import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { API_PEDIDOS } from '../../shared/services/pedidos';
import { toast } from 'react-toastify';
import moment from 'moment-timezone';
import ScannerForm from '../../shared/components/ScannerForm';
import { useDispatch } from 'react-redux';
import { setErrorMessage } from '../../shared/helpers/functionalities';

export default function Step1({ pedidos, setPedidos }) {
  const dispatch = useDispatch();

  const handleScanPedido = async (scannedPedido) => {
    if ( pedidos.find(
        (pedido) => pedido.pedido.numero_presupuesto === scannedPedido)
    ) {
      toast.warning(`El pedido: ${scannedPedido} ya está en la lista.`);
      return;
    }

    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.getPedidoByNumPresu(scannedPedido)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      const pedido = response.pedido;
      const pedido_to_add = { aceptado: false, pedido: pedido };
      setPedidos([...pedidos, pedido_to_add]);
    }
  };

  const handleRemovePedido = (index) => {
    const newPedidos = [...pedidos];
    newPedidos.splice(index, 1);
    setPedidos(newPedidos);
  };

  return (
    <div className='w-100 mt-3'>
      <h1>Recogida de pedidos</h1>
      <p className='mt-3'>
        Con el lector de código de barras, escanea los pedidos. Una vez veas
        reflejado en el campo de texto inferior el número/s de pedido/s, haz
        click en siguiente.
      </p>

      <div className='w-100 d-flex align-items-center justify-content-center my-4'>
        <div className="col-12 col-md-6">
            <div className="position-relative">
              <ScannerForm 
                type={'recogida'}
                onScan={handleScanPedido}
              />
          </div>
        </div>
      </div>

      <div className='w-100 table-responsive'>
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>presupuesto</th>
              <th className='text-center'>fecha</th>
              <th>piezas</th>
              <th></th>
            </tr>
          </thead>

            {pedidos?.length === 0 ? (
              <tbody>
                <tr>
                  <td colSpan={5} className="text-center">
                    No hay pedidos escaneados
                  </td>
                </tr>
              </tbody>
            ) : (
              pedidos?.map((pedido, index) => {
                const piezasValues = Object.values(pedido.pedido.piezas)
                const piezasRows = piezasValues.map((pieza, i) => {
                  
                  const codigoPedido = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                    {(pedido.pedido.codigo_pedido !== '' && pedido.pedido.codigo_pedido !== null) 
                      ? pedido.pedido.codigo_pedido 
                      : '-'
                    }
                  </td> : null
                  const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                    {pedido.pedido.numero_presupuesto}
                  </td> : null
                  const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                    {moment(pedido.pedido.fecha).tz('Europe/Madrid').format('DD-MM-YYYY HH:mm:ss')}
                  </td> : null
                  
                  const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                    <span title="Eliminar" onClick={() => handleRemovePedido(i)} style={{cursor: 'pointer'}}>
                        <FontAwesomeIcon icon={faTrash} size="1x" className="action" color="#dc3545" />
                    </span>
                  </td> : null
                        
                  return (
                    <tr key={i}>
                      {codigoPedido}
                      {presupuesto}
                      {fecha}
                      <td>
                        <div className='w-100 d-flex align-items-center'>
                          <div className='flex-grow-1 d-flex flex-column'>
                            <span>{pieza.nombre}</span>
                            <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                            <span>REF: {pieza.referencia_pieza}</span>
                          </div>
                        </div>
                      </td>
                      {acciones}
                    </tr>
                  )
                })

                return (
                  <tbody key={index}>
                    {piezasRows}
                  </tbody>
                )
              })
            )
          }
        </table>
      </div>
    </div>
  );
}
