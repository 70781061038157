import PropTypes from 'prop-types';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../../images/logo399x69.png';
import initImage from '../../images/initImage.jpeg';
import { authenticate } from '../../api/auth';
import config from '../../config';
import { setErrorMessage } from '../Main/components/shared/helpers/functionalities';
import { useDispatch } from 'react-redux';

export default function Login({ setToken }) {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || '/';
  const username = useFormInput('');
  const password = useFormInput('');

  const handleLogin = async (e) => {
    e.preventDefault();

    const response = await authenticate({
      username: username.value,
      password: password.value,
    }).catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });

    if (response && response.success) {
      setToken(response.user);
      if (response.user.rol_id === 3) return navigate('/vin');
      navigate(from, { replace: true });
    }
  };

  return (
    <div
      className="login w-100 h-100 d-flex flex-column align-items-center justify-content-center"
      style={{
        backgroundImage: `url(${initImage})`,
        backgroundRepeat: 'round',
        maxWidth: '1920px',
      }}
    >
      <div className="col-md-7 col-lg-5 col-xl-4 py-4 px-5">
        <img
          src={logo}
          className="logo d-block m-auto mt-3 mb-4"
          alt="Recomotor"
        />

        <form
          className="form d-flex flex-column align-items-stretch pt-3 pb-4"
          autoComplete="on"
          onSubmit={handleLogin}
        >
          <div className="form-group mb-3">
            <input
              type="text"
              className="form-control"
              placeholder="Username"
              {...username}
            />
          </div>

          <div className="form-group">
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              {...password}
            />
          </div>

          <button type="submit" className="btn btn-primary mt-3">
            Acceder
          </button>
        </form>
      </div>
      <p>v.{config.app.version}</p>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

const useFormInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return {
    value,
    onChange: handleChange,
  };
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};
