import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { getAllowedExt } from "../../../shared/helpers/functionalities";

export default function EtiquetaForm({
    onSubmitEtiqueta
}) {
    const { register, handleSubmit } = useForm({
        defaultValues: {
            id: 0,
            titulo: '',
            descripcion: '',
            tipo: ''
        }
    });

    const handleSubmitEtiqueta = async(data, e) => {
        e.preventDefault();
        const dataForm = new FormData();

        if(data.etiqueta.length > 0) {
            dataForm.append('etiqueta', data.etiqueta[0]);
        } else {
            toast.warning('Tienes que seleccionar una etiqueta!');
            return;
        }

        onSubmitEtiqueta(dataForm);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitEtiqueta)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group">
                        <label htmlFor="etiqueta">Etiqueta</label>
                        <input 
                            type="file" 
                            className="form-control" 
                            accept={getAllowedExt('pdf')}
                            {...register("etiqueta", { maxLength: 255, required: true })} 
                            defaultValue={''} />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}