import { useState } from "react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InfoModal from "../../shared/components/modals/Info";
import TransportistaForm from "./TransportistaForm";
import { API_TRANSPORTISTAS } from "../../shared/services/transportistas";
import { fetchTransportistas } from "../../../../../redux/transportistas";
import { setErrorMessage } from "../../shared/helpers/functionalities";

export default function Transportistas() {
    const dispatch = useDispatch();
    const [currentTransportista, setCurrentTransportista] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    
    const transportistas = useSelector(state => {
        const { transportistas } = state;
        return transportistas;
    });

    const handleOpenForm = (transportista = null) => {
        setCurrentTransportista(transportista);
        setOpenForm(true);
    }

    const handleCloseForm = () => { 
        setOpenForm(false);
        setCurrentTransportista(null);
    }

    const handleSubmitTransportista = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        let response;

        if(currentTransportista) {
            response = await API_TRANSPORTISTAS.updateTransportista(data)
            .catch(function (error) {
                dispatch({type: 'loading/set', payload: false});
                toast.error(setErrorMessage(error))
            });
        }

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message);
            dispatch(fetchTransportistas());
            handleCloseForm();
        }
    }

    return (
        <div className="w-100 d-flex flex-column align-items-center"> 
            <div className="w-100 d-flex align-items-center justify-content-between mb-4">
                <button className="btn btn-add" onClick={() => handleOpenForm()} hidden={true}>
                    <span className="add">
                        <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                    </span>
                    Nuevo
                </button>
            </div>

            <div className="w-100 table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th>Nombre</th>
                            <th>Hora de entrega</th>
                            <th className="text-center">acciones</th>
                        </tr>
                    </thead>

                    <tbody>
                        
                        { transportistas?.length > 0 ? transportistas?.map((transportista) => {
                                return (
                                    <tr key={'transportista-' + transportista.id}>
                                        <td>{transportista.nombre}</td>
                                        <td>{transportista.hora_entrega}</td>
                                        <td width={'15%'} className="text-center">
                                            <span title="Editar" style={{cursor: 'pointer'}}>
                                                <FontAwesomeIcon icon={faPen} size="1x" className="action" color="#215732" onClick={() => handleOpenForm(transportista)} />
                                            </span>
                                        </td>
                                    </tr>
                                )
                            })
                            : 
                            <tr>
                                <td colSpan={2} className="text-center">No hay resultados</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>

            { (openForm) && 
                <InfoModal
                    state={openForm}
                    title={(currentTransportista ? 'Editar' : 'Nueva') + ' transportista'}
                    content={
                        <TransportistaForm 
                            transportista={currentTransportista}
                            onSubmitTransportista={handleSubmitTransportista}
                        />
                    }
                    width={'sm'}
                    onClose={handleCloseForm}
                ></InfoModal>
            }
        </div>
    )
    
}