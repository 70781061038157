export const SidebarData = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    cName: 'nav-text',
    permission: [1, 2, 4],
  },
  {
    title: 'Etiquetas Sidegal',
    path: '/sidegal',
    cName: 'nav-text',
    permission: [1],
  },
  {
    title: 'Usuarios',
    path: '/usuarios',
    cName: 'nav-text',
    permission: [1],
  },
  {
    title: 'Pedidos pendientes de recoger',
    path: '/recogida',
    cName: 'nav-text',
    permission: [0],
  },
  {
    title: 'Introducir Vin',
    path: '/vin',
    cName: 'nav-text',
    permission: [1, 3],
  },
  {
    title: 'Recogida de pedidos',
    path: '/carrier',
    cName: 'nav-text',
    permission: [0],
  }
];
