import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import React, { memo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../../../../../images/logo_white.png';
import UserAvatar from './components/UserAvatar';
import './Navbar.scss';
import { SidebarData } from './SidebarData';

const Navbar = ({ onLogout, userRol }) => {
  let pathname =
    window.location.pathname === '/'
      ? 'inicio'
      : window.location.pathname.replace('/', '');
  // const currentUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();
  const [sideBar, setSidebar] = useState(false);

  const handleClickMenuItem = (path) => {
    navigate(path);
  };
  const showSidebar = (show) => {
    setSidebar(show);
  };

  const NavItem = memo(({ children }) => {
    return <span>{children}</span>;
  }, isEqual);

  return (
    <>
      <div className="navbar-sidebar">
        <Link
          to="#"
          className="menu-bars"
          onMouseEnter={() => showSidebar(true)}
        >
          <FontAwesomeIcon icon={faBars} size="2x" color="#215732" />
        </Link>
      </div>

      <nav
        className={sideBar ? 'nav-menu active' : 'nav-menu'}
        onMouseLeave={() => showSidebar(false)}
      >
        <ul className="nav-menu-items">
          <li className="mb-3 nav-menu-items__header">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div className="navbar-brand">
                <img
                  src={logo}
                  className="brand"
                  style={{ height: '60px' }}
                  alt="Recomotor"
                />
              </div>
              {<UserAvatar style={{ cursor: 'pointer' }} onLogout={onLogout} />}
            </div>
          </li>
          {SidebarData.map((item, index) => {
            if ((item.permission[0] === 0 && userRol !== 3) || item.permission.includes(userRol)) {
              return (
                <NavItem key={index}>
                  <span
                    onClick={() => handleClickMenuItem(item.path)}
                    className={`${item.cName} 
                  ${item.title.toLowerCase() === pathname ? 'active' : ''}`}
                  >
                    <li style={{ cursor: 'pointer' }}>
                      <span>{item.title}</span>
                    </li>
                  </span>
                </NavItem>
              );
            } else {
              return null;
            }
          })}
        </ul>
      </nav>
    </>
  );
};

export default memo(Navbar);
