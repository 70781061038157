export const headers = {
  headers: {
    "Content-Type": "multipart/form-data",
  }
};

export const setErrorMessage = (error) => {
  
  if (error.response) {
      return error.response.data.message;
  } else if (error.request) {
      return 'Ups... algo ha ido mal';
  } else {
      return error.message;
  }
}

export const getStatusPedido = (estadoId) => {
  if(estadoId === 1 || estadoId === 2) return 'text-warning';
  else if(estadoId === 3) return 'text-info';
  else if(estadoId === 6) return 'text-danger';
  else return 'text-green';
}

export const validFileExtensions = {image: ['jpg', 'png', 'jpeg', 'webp'], excel: ['xlsx', 'xls'], csv: ['csv'], pdf: ['pdf']};
export const getAllowedExt = (type) => validFileExtensions[type].map((e) => `.${e}`).toString();

export const formatUrlImage = (CDNservices, image) => {
    const cdn_img = CDNservices.find(
      (cdn) => cdn.id === image.cdn_service_id
    );
    const url_img = cdn_img.id !== 5 ? (cdn_img.base_path + image.imagen_url) : image.imagen_url;
    return url_img;
};