export const getActions = (pedido, type, isAdmin) => {
  const actions = [];
  if(type === 'historico') {
    // RECOGIDO
    if(pedido.estado_id !== 5) {
      actions.push({
        label: 'Recuperar pedido',
        icon: 'restore',
        action: 'recuperar_pedido',
      });
    }

    actions.push({
      label: 'Ver logs',
      icon: 'log',
      action: 'logs',
    });
  } else {
    if(pedido.estado_id === 1) {                  // PENDIENTE DE DESCARGA
      actions.push({
        label: 'Marcar pedido como descargado',
        icon: 'down',
        action: 'pedido_descargado',
      });
    } else if(pedido.estado_id === 2) {           // DESCARGADO
      if(!pedido.user_id) {
        actions.splice(2, 0,  {
          label: 'Asignar pedido a',
          icon: 'assign',
          action: 'asignar_pedido',
        });
      } else {
        actions.splice(2, 0,  {
          label: 'Desasignar pedido',
          icon: 'desassign',
          action: 'desasignar_pedido',
        });
      }
    } else if(pedido.estado_id === 3) {           // ASIGNADO
      if(!isAdmin) {
        actions.push({
          label: 'Marcar Pedido como Completado',
          icon: 'order',
          action: 'pedido_completado',
        });
      }
    }

    if(pedido.piezas.find(p => p.transportista_id === 1 || p.transportista_id === null)) {
      actions.push({
        label: 'Asignar transportista',
        icon: 'assign',
        action: 'asignar_transportista',
      });
    }

    if(pedido.piezas.find(p => p.etiqueta_envio)) {
      actions.push({
        label: 'Imprimir Etiqueta',
        icon: 'print',
        action: 'imprimir_etiqueta',
    },{
      label: 'Previsualizar Etiqueta',
      icon: 'preview',
      action: 'preview_etiqueta',
    })
    } else {
      if(pedido.plataforma_id !== 3) {
        actions.push({
          label: 'Subir etiqueta',
          icon: 'upload_label',
          action: 'subir_etiqueta',
        }, {
          label: 'Solicitar etiqueta a la oficina',
          icon: 'label_request',
          action: 'solicitar_etiqueta',
        });
      }
    }

    actions.push({
      label: 'Eliminar Pedido',
      icon: 'shipment_cancel',
      action: 'pedido_rechazado',
    });

    actions.push({
      label: 'Ver logs',
      icon: 'log',
      action: 'logs',
    });
  }

  return actions;
};
