import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_PIEZAS_SIDEGAL } from "../shared/services/sidegal";
import { toast } from "react-toastify";
import { setErrorMessage } from "../shared/helpers/functionalities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import InfoModal from "../shared/components/modals/Info";
import SidegalForm from "./SidegalForm";
import { Pagination } from "@mui/material";
import { fetchPiezasSidegal } from "../../../../redux/features/piezasSidegal";
import { getEtiquetaPieza } from "../../../../services/pdf";
import ScannerForm from "../shared/components/ScannerForm";

export default function Sidegal() {
    const dispatch = useDispatch();
    const piezasSidegal = useSelector(state => {
        return state.piezas_sidegal.entity
    }); 
    const [idRef, setIdRef] = useState('');
    const [currentPage, setPage] = useState(1);
    const [filteredPiezas, setFilteredPiezas] = useState([]);
    const [currentPieza, setCurrentPieza] = useState(null);
    const filterValues = useRef({
        id: ''
    });
    const [openForm, setOpenForm] = useState(false);

    useEffect(() => {
        dispatch(fetchPiezasSidegal(1));
    }, [])

    useEffect(() => {
        if(piezasSidegal?.piezas?.rows?.length > 0) {
            setFilteredPiezas(piezasSidegal?.piezas.rows)
        }
    }, [piezasSidegal])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        dispatch(fetchPiezasSidegal(newPage, filterValues.current));
    };

    const getPaginationCount = () => {
        const isFilterEmpty = Object.values(filterValues.current).every(x => x === null || x === '');
        const total = isFilterEmpty ? (piezasSidegal?.piezas?.count ? piezasSidegal?.piezas.count : 1) : filteredPiezas.length;
        var totalPages = total < 15 ? 1 : Math.ceil(total / 15);
        return parseInt(totalPages);
    }

    const handleFilterChange = (value) => {
        setIdRef(value);

        if(value === "") {
            filterValues.current = {
                id: ''
            }
        } else {
            filterValues.current = {...filterValues.current, id: value}
        }

        handleChangePage(null, currentPage);
    }

    const handleSaveUbicacion = async(data) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await API_PIEZAS_SIDEGAL.saveUbicacion(data)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });

        if(response && response.success) {
            toast.success(response.message);
            dispatch({type: 'loading/set', payload: false});
            dispatch(fetchPiezasSidegal(currentPage, filterValues.current));

            await getEtiquetaPieza(currentPieza)
            .catch(function (error) {
                dispatch({type: 'loading/set', payload: false});
                toast.error(setErrorMessage(error))
            });
        }
    }

    const handleOpenForm = (pieza) => {
        setCurrentPieza(pieza);
        setOpenForm(true);
    }
    const handleCloseForm = () => {
        setOpenForm(false);
        setCurrentPieza(null);
    }
  
    return (
        <div className="content">
        <h1>Etiquetas Sidegal</h1>

        <div className='w-100'>
                <div className="w-100 d-flex align-items-center justify-content-between mt-3">
                    <p>
                        Piezas almacenadas: 
                        <span className="fw-bold ms-1">{piezasSidegal ? piezasSidegal.almacenadas : '-'}</span>
                    </p>
                    
                    <div className="col-12 col-md-3">
                        <ScannerForm 
                            type={'pieza'}
                            onScan={handleFilterChange}
                        />
                    </div>  
                </div>

                <div className="w-100 d-flex flex-column align-items-end mt-4">
                    <div className="w-100 table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Nombre</th>
                                    <th>Referencia pieza</th>
                                    <th className="text-center">Ubicación</th>
                                    <th className="text-center">Seinto ID</th>
                                    <th className="text-center"></th>
                                </tr>
                            </thead>

                            <tbody>
                                { filteredPiezas?.length > 0 ? 
                                    filteredPiezas.map(pieza => {
                                        return (
                                            <tr key={'sidegal-' + pieza.id}>
                                                <td>{pieza.id_referencia_plataforma}</td>
                                                <td>{pieza.nombre}</td>
                                                <td>{pieza.referencia_pieza}</td>
                                                <td className="fw-bold text-center">{pieza.ubicacion ? pieza.ubicacion : '-'}</td>
                                                <td className="text-center">{pieza.seinto_id}</td>
                                                <td className="text-center">
                                                    <span className="action" onClick={() => handleOpenForm(pieza)}>
                                                        <FontAwesomeIcon icon={faEdit} size="1x" color="#215732" />
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={5} className="text-center">No hay resultados.</td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>

                    <Pagination 
                        className="mt-3" 
                        count={getPaginationCount()} 
                        page={currentPage} 
                        onChange={handleChangePage} 
                    />
                </div>
            </div>

            { (openForm) &&
                <InfoModal
                    title={`Añadir ubicación #${currentPieza.id_referencia_plataforma} ${currentPieza.nombre}`}
                    content={
                        <SidegalForm 
                            pieza={currentPieza}
                            onSubmitInfo={handleSaveUbicacion}
                        />
                    }
                    state={openForm}
                    width={"sm"}
                    onClose={handleCloseForm}
                />
            }
        </div>
    );
}