import API from '../../../../../services/api';

export const API_USERS = {
    getUsers: async () => {
      return await API.get(`/usuarios`).then((res) => res.data);
    },
    newUser: async (user) => {
      return await API.post(`/usuarios/`, user).then((res) => res.data);
    },
    updateUser: async (id, user) => {
      return await API.put(`/usuarios/${id}`, user).then((res) => res.data);
    },
    toggleUser: async (id) => {
      return await API.put(`/usuarios/${id}/toggle`).then((res) => res.data);
    },
  };