import React, { useEffect, useState } from 'react';
import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import { useSelector } from 'react-redux';

const BASIC_STATE = {
  imprimir: false,
  marcar: { completado: false, rechazado: false, pendiente: false },
  assignar: { habilitado: false, user: '' },
};

export default function MassiveActionsModal({
  from,
  state,
  isAdmin,
  width,
  selectedPedidos,
  onClose,
  onReturn,
}) {
  const users = useSelector(state => {
    const { users } = state;
    return users;
  });
  const [usuarios, setUsuarios] = useState([]);
  const [opcionesMassivas, setOpcionesMassivas] = useState(BASIC_STATE);

  useEffect(() => {
    if (state) {

      if (from === 'general') {
        const usersList = users.filter(u => u.rol_id === 2);
        setUsuarios(usersList); 
      }
    }

    setOpcionesMassivas(BASIC_STATE);
  }, [state, from]);

  const handleOptionChange = (event) => {
    const value = event.target.value;
    if (value === 'imprimir') {
      setOpcionesMassivas({
        imprimir: true,
        marcar: { completado: false, rechazado: false, pendiente: false },
        assignar: { habilitado: false, user: '' },
      });
    } else if (
      value === 'completado' ||
      value === 'rechazado' ||
      value === 'pendiente'
    ) {
      setOpcionesMassivas({
        imprimir: false,
        marcar: {
          completado: value === 'completado',
          rechazado: value === 'rechazado',
          pendiente: value === 'pendiente',
        },
        assignar: { habilitado: false, user: '' },
      });
    } else if (value === 'asignar') {
      setOpcionesMassivas((prevState) => ({
        ...prevState,
        imprimir: false,
        marcar: { completado: false, rechazado: false, pendiente: false },
        assignar: { habilitado: true, user: '' },
      }));
    }
  };

  const handleUserChange = (event) => {
    const userId = event.target.value;
    setOpcionesMassivas((prevState) => ({
      ...prevState,
      assignar: { ...prevState.assignar, user: userId },
    }));
  };

  return (
    <Dialog
      open={state}
      onClose={onClose}
      fullWidth={true}
      maxWidth={width ? width : 'sm'}
      aria-labelledby="info-title"
      aria-describedby="info-description"
    >
      <DialogTitle id="info-title">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <p className="text-uppercase fw-bold">Selector de Acciones Masivas</p>
          <span title="cerrar" className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
          </span>
        </div>
        <div>
          <p>
            Selecciona la acción que deseas realizar de forma masiva en los
            pedidos.
          </p>
        </div>
      </DialogTitle>
      <DialogContent>
          <div>
            <div>
              <p>¿Qué acción deseas realizar?</p>
              <FormControl fullWidth variant="outlined" size="small" margin="normal">
                <Select
                  value={
                    opcionesMassivas.imprimir
                      ? 'imprimir'
                      : opcionesMassivas.marcar.completado
                      ? 'completado'
                      : opcionesMassivas.marcar.rechazado
                      ? 'rechazado'
                      : opcionesMassivas.marcar.pendiente
                      ? 'pendiente'
                      : opcionesMassivas.assignar.habilitado
                      ? 'asignar'
                      : ''
                  }
                  onChange={handleOptionChange}
                >
                  <MenuItem value="imprimir">Imprimir</MenuItem>
                  { (!isAdmin) && 
                    <MenuItem value="completado">
                      Marcar Pedido Completado
                    </MenuItem>
                  }
                  { (!isAdmin) && 
                    <MenuItem value="rechazado">Marcar Pedido Rechazado</MenuItem>
                  }
                  
                  
                  {from === 'general' ? (
                    <MenuItem value="asignar">Asignar</MenuItem>
                  ) : (
                    <MenuItem value="pendiente">
                      Marcar Pedido Pendiente
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {opcionesMassivas.assignar.habilitado && (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="usuario-label">
                    Seleccionar Usuario
                  </InputLabel>
                  <Select
                    labelId="usuario-label"
                    value={opcionesMassivas.assignar.user || ''}
                    onChange={handleUserChange}
                  >
                    {usuarios.map((usuario) => (
                      <MenuItem key={usuario.id} value={usuario.id}>
                        {usuario.username}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </div>

            { (opcionesMassivas.imprimir) &&
              <div className=''>
                { selectedPedidos.filter(p => p.piezas.filter(pi => !pi.etiqueta_envio))?.length > 0 ?
                  <span className='d-flex align-items-start justify-content-center mt-3'>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#ffc107" /> 
                    <small className='text-center'>
                      Ten en cuenta que has seleccionado uno o más pedidos que no disponen de etiqueta, solo se imprimirán los que sí tengan. &nbsp;
                    </small>
                    <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#ffc107" /> 
                  </span>
                  :
                  ''
                }
              </div>
            }

            <div className="d-flex justify-content-end mt-3">
              <button
                className="btn btn-primary"
                onClick={() => {
                  onReturn(opcionesMassivas);
                  onClose();
                }}
              >
                Ejecutar
              </button>
            </div>
          </div>
      </DialogContent>
    </Dialog>
  );
}
