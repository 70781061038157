import { Search } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';

export default function ScannerForm({
    type,
    onScan
}) {
    const [inputValue, setInputValue] = useState('');
    const inputCodigo = useRef(null);

    useEffect(() => {
        if(inputCodigo.current) {
            inputCodigo.current.focus();
        }
    }, []);

    const validateInput = (input) => { return type === 'pieza' ?  /^[0-9]+$/.test(input) : true; };
    const handleChangeInput = (e) => {
        setInputValue(e.target.value)
    }

    const handleEnterInput = async (e) => {
        if (e.key === 'Enter') {
            if (!validateInput(inputValue)) {
                toast.error(`El código de la pieza debe contener solo números.`);
                return;
            }

            setInputValue('');

            if (inputCodigo.current) {
                inputCodigo.current.focus();
                inputCodigo.current.value = '';
            }

            if(type !== 'pieza')  {
                const numPresu = inputValue.replace("'", "-")
                onScan(numPresu);
            } else { 
                onScan(parseInt(inputValue));
            }
        }
    };

    return (
        <div className='form-group'>
            <div className="input-group d-flex align-items-center justify-content-between">
                <input 
                    type="text" 
                    className="form-control search-input" 
                    defaultValue={inputValue} 
                    id="scanner" 
                    placeholder="Escanear código de barras"
                    onKeyDown={(e) => handleEnterInput(e)}
                    onChange={(e) => handleChangeInput(e)}
                    ref={inputCodigo}
                />
                <span className='search-input-icon'>
                    <Search
                        cursor="pointer"
                        style={{ color: '$primary' }}
                        onClick={() => handleEnterInput({ key: 'Enter' })}
                    />
                </span>
            </div>
        </div>
    );
}
