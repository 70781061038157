import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEuroSign, faFileImage } from '@fortawesome/free-solid-svg-icons';
import SwiperSlider from '../../shared/components/SwiperSlider';
import { statesData } from '../data';
import { API_VIN } from '../../shared/services/vin';

const VinForm = ({
    piezaInfo,
    isEdit,
    onSubmit,
}) => {
    const dispatch = useDispatch();
    const {
        register,
        formState: { errors },
        setValue,
        handleSubmit
    } = useForm({
            defaultValues: {
            id: piezaInfo.id,
            Articulo: piezaInfo.Articulo,
            ref1: piezaInfo.ref1,
            ref2: piezaInfo.ref2,
            ref3: piezaInfo.ref3,
            estado: piezaInfo.estado,
            precio: piezaInfo.precio,
            ubica: piezaInfo.ubica,
            obspub: piezaInfo.obspub,
            obspri: piezaInfo.obspri
        }
    });

  const [isLoadingImgs, setIsLoadingImgs] = useState(false)
  const [images, setImages] = useState([]);
  const [mainImgId, setMainImgId] = useState(null)
  const [price, setPrice] = useState(piezaInfo.precio)
  const [isDisabledProductDetail, setIsDisabledProductDetail] = useState(false)

  useEffect(() => {
    imagesReq(piezaInfo.id)
  }, [])

  const imagesReq = async(partId) => {
    dispatch({type: 'loading/set', payload: true});
    setIsLoadingImgs(true)

    const res = piezaInfo.estado === 13 ?  await API_VIN.getImagesByPartIdDesm(partId) : await API_VIN.getImagesByPartId(partId);
    if(res?.success){
        dispatch({type: 'loading/set', payload: false});
        setImages(res.images.data)
        if(res.images.data.length > 0) {
            const img = res.images.data.find(img => img.prin === 1)
            setMainImgId(img?.idLinea)

            const isProductDetail = res.images.data.find(img => (img.ruta).includes('detallesProducto'))
            setIsDisabledProductDetail(isProductDetail ? true : false)
        }
        return setIsLoadingImgs(false)
    }

    setIsLoadingImgs(false)
    return toast.error('Algo ha ido mal')
  }

  const handleSubmitPart = async(data, e) => {
    e.preventDefault();
    const newData = {
      idVPiezaRef: data.ref1,
      idVPiezaref2: data.ref2,
      idVPiezaequi: data.ref3,
      estpie_cod: data.estado,
      precioV: data.precio,
      observ: data.obspub,
      obsint: data.obspri,
      idLinea: mainImgId
    }
   
    onSubmit(newData);
  }

  const handleFilterPrice = (e) => {
    if ((e.target.value).includes('.')) {
      const decimalLength = (e.target.value).indexOf('.') + 1
      if ((e.target.value).length - decimalLength > 2) {
        return setPrice((e.target.value).slice(0, -1))
      }
    } else if (Number(e.target.value) > 99999999.99) {
      return toast.error('No se admiten valores de más de 8 dígitos')
    } else if (Number(e.target.value) < 0) {
      return toast.error('El precio no puede ser menor que 0')
    }
    
    setPrice(e.target.value)
  }

  const addProductDetail = async() => {
    if(isLoadingImgs) return 
    const res = await API_VIN.getProductDetail({idPiezaDesp: piezaInfo.id})
    if(res?.success) {
      setIsDisabledProductDetail(true)
      return imagesReq(piezaInfo.id)
    }
    return toast.error('Algo ha ido mal...')
  }


  return (
    <div className="w-100 d-flex flex-column">
        <div className='w-100'>
            <SwiperSlider 
                isLoadingImgs={isLoadingImgs} 
                isEdit={isEdit} 
                images={images} 
                mainImgId={mainImgId} 
                setMainImgId={setMainImgId} 
            />
            
            <div className="w-100 d-flex align-items-center justify-content-end">
                <button 
                    type="button" 
                    className="btn btn-add my-4" 
                    onClick={() => addProductDetail()}
                    disabled={isDisabledProductDetail}
                >
                    <span className="add">
                        <FontAwesomeIcon icon={faFileImage} size="1x" className="action" color="#215732" />
                    </span>
                    Añadir IMG "pieza con desperfectos"
                </button>
            </div>
        </div>

        <form className="form flex-column align-items-center my-4" onSubmit={handleSubmit(handleSubmitPart)}>
            <div className='w-100 d-flex align-items-center'>
                <div className="form-group me-3">
                    <label htmlFor="id">ID</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("id", { required: true })}
                        disabled={true}
                        defaultValue={piezaInfo.id} />
                </div>

                <div className="form-group">
                    <label htmlFor="Articulo">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("Articulo", { required: true })}
                        disabled={true}
                        defaultValue={piezaInfo.Articulo} />
                </div>
            </div>

            <div className='w-100 d-flex align-items-center'>
                <div className="form-group me-3">
                    <label htmlFor="ref1">Referencia</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("ref1", { required: true })}
                        defaultValue={piezaInfo.ref1} />

                    { (!!errors['ref1']) &&
                        <div className="form-invalid">
                            {errors.ref1 && "La referencia principal es obligatoria"}
                        </div>
                    }
                </div>

                <div className="form-group">
                    <label htmlFor="ref2">Referencia 2</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("ref2")}
                        defaultValue={piezaInfo.ref2} />
                </div>
            </div>

            <div className='w-100 d-flex align-items-center'>
                <div className="form-group me-3">
                    <label htmlFor="ref2">Referencia 3</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("ref3")}
                        defaultValue={piezaInfo.ref3} />
                </div>

                <div className="form-group">
                    <label htmlFor="estado">Estado</label>
                    { (statesData && statesData.length > 0) &&
                        <select 
                            {...register("estado", {required: true})}
                            defaultValue={piezaInfo.estado}
                            onChange={(e) => setValue('estado', e.target.value)}>
                            {statesData.map(op => <option key={'estado-' + op.estpie_cod} value={op.estpie_cod}>{op.nom}</option>)}
                        </select>
                    }

                    { (!!errors['estado']) &&
                        <div className="form-invalid">
                            {errors.estado && "El estado de la pieza es obligatorio"}
                        </div>
                    }
                </div>
            </div>

            <div className='w-100 d-flex align-items-center'>
                <div className="form-group me-3">
                    <label htmlFor="min">Precio</label>
                    <div className="position-relative">
                        <input 
                            type="number" 
                            step="0.01"
                            className="form-control" 
                            min={0}
                            {...register("precio", { required: true })} 
                            onChange={(e) => handleFilterPrice(e)}
                            defaultValue={price}
                        />
                        <FontAwesomeIcon icon={faEuroSign} color="#215732" className="icon" />
                    </div>
                    
                    <div className="form-invalid">
                        {errors.precio && "El precio es obligatorio y tiene que ser mayor que 0"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="ubica">Ubicación</label>
                    <input 
                        type="text" 
                        className="form-control"
                        {...register("ubica")}
                        disabled={true}
                        defaultValue={piezaInfo.ubica} />
                </div>
            </div>
           

            <div className="form-group">
                <label htmlFor="obspub">Observaciones públicas</label>
                <textarea
                    {...register("obspub")}
                    defaultValue={piezaInfo.obspub}
                ></textarea>
            </div>

            <div className="form-group">
                <label htmlFor="obspri">Observaciones privadas</label>
                <textarea
                    {...register("obspri")}
                    defaultValue={piezaInfo.obspri}
                ></textarea>
            </div>
            

            <div className="w-100 d-flex align-items-center justify-content-end mt-4">
                <button type="submit" className="btn btn-primary">Guardar</button>
            </div>
        </form>
    </div>   
  )
}

export default VinForm
