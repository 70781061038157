import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

export default function UserForm({user, onSubmitUser}) {
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        defaultValues: {
            id: '',
            username: '',
            nombre: '',
            password: '',
            rol_id: 1,
        }
    });
    const roles = useSelector(state => {
        const { roles } = state;
        return roles;
    });

    useEffect(() => {
        if(user) {
            reset(user);
        } else reset({
            id: '',
            username: '',
            nombre: '',
            password: '',
            rol_id: 1,
        });
    }, [user]);

    const handleChangeRol = (e) => { setValue('rol_id', parseInt(e.target.value)); }

    const onSubmit = async (data, e) => {
        e.preventDefault();
       onSubmitUser(data);
    }

    return (
        <form className="w-100 form" onSubmit={handleSubmit(onSubmit)}>
            <input 
                type="hidden" 
                {...register("id")} 
                defaultValue={user ? user.id : ''} />

            <div className="w-100">
                <div className="form-group">
                    <label htmlFor="username">Username</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        {...register("username", { required: true, maxLength: 50 })} 
                        defaultValue={user ? user.username : ''} />
                    
                    <div className="form-invalid">
                        {errors.username?.type === 'required' && "El username es obligatorio"}
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="nombre">Nombre</label>
                    <input 
                        type="text" 
                        className="form-control" 
                        {...register("nombre", { required: true, maxLength: 255 })} 
                        defaultValue={user ? user.nombre : ''} />

                    <div className="form-invalid">
                        {errors.nombre?.type === 'required' && "El nombre es obligatorio"}
                    </div>
                </div>

                { !user &&
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input 
                            type="password" 
                            className="form-control" 
                            {...register("password", { required: true, maxLength: 25 })} 
                            defaultValue={''} />

                        <div className="form-invalid">
                            {errors.password?.type === 'required' && "La password es obligatoria"}
                        </div>
                    </div>
                }

                <div className="form-group">
                    <label htmlFor="rol_id">Rol</label>
                    { (roles && roles.length > 0) &&
                        <select 
                            {...register("rol_id")}
                            defaultValue={user ? user.rol_id : 1}
                            onChange={handleChangeRol}>
                            {roles.map(op => <option key={'roles-' + op.id} value={op.id}>{op.nombre}</option>)}
                        </select>
                    }

                    <div className="form-invalid">
                        {errors.rol_id?.type === 'required' && "El rol es obligatorio"}
                    </div>
                </div>
            </div>

            <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
        </form>
    )
}