// LOAD ALL THE READ ONLY DATA
import { toast } from "react-toastify";
import { makeSetReducer } from "./utils";
import { API_TRANSPORTISTAS } from "../views/Main/components/shared/services/transportistas";

export const transportistasFullfilledReducer = makeSetReducer(['transportistas/fullfilled']);

export const fetchTransportistas = () => async dispatch => {
    try {
        const response = await API_TRANSPORTISTAS.getTransportistas();
        const data = await response.transportistas;
        const local = JSON.parse(localStorage.getItem('read_only'));
        localStorage.setItem('read_only', JSON.stringify({...local, transportistas: data}));
        dispatch({type: 'transportistas/fullfilled', payload: data});
    } catch (e) {
        toast.error(e.message)
    }
}