import { Navigate, Route, Routes } from 'react-router-dom';
import Config from './components/shared/components/Config';
import Navbar from './components/shared/components/Navbar';
import Dashboard from './components/Dashboard';
import MiCuenta from './components/Mi-cuenta';
import Recogida from './components/Recogida';
import Carrier from './components/Carrier';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import LoadingBackdrop from './components/shared/components/LoadingBackdrop';
import NewVersionModal from './components/shared/components/NewVersionModal';
import { fetchReadOnlyData } from '../../redux/readOnly';
import 'react-toastify/dist/ReactToastify.css';
import Vin from './components/Vin';
import Usuarios from './components/Usuarios';
import Sidegal from './components/Sidegal';
import Ajustes from './components/Ajustes';

const Main = ({ onLogout, userRol }) => {
  const dispatch = useDispatch();
  const loading = useSelector(state => {
      return state.loading
  })
  const reload = useSelector(state => {
      return state.reload
  })

  const getDefaultRoute = () => {
    switch (userRol) {
      case 5:
        return '/carrier';
      case 3:
        return '/vin';
      default:
        return '/dashboard';
    }
  };

  useEffect(() => {
    // MAKE INITIAL LOAD OF READ ONLY DATA ON REDUX STORE AND CHECK IF IS SAVED IN LOCALSTORAGE
    if(localStorage.getItem('read_only')) {
        const data = JSON.parse(localStorage.getItem('read_only'))
        dispatch({type: 'roles/fullfilled', payload: data.roles});
        dispatch({type: 'plataformas/fullfilled', payload: data.plataformas});
        dispatch({type: 'cdn_services/fullfilled', payload: data.cdn_services});
        dispatch({type: 'hosts/fullfilled', payload: data.hosts});
        dispatch({type: 'estados/fullfilled', payload: data.estados});
        dispatch({type: 'tipo_origenes/fullfilled', payload: data.tipo_origenes});
        dispatch({type: 'actualizaciones/fullfilled', payload: data.actualizaciones});
        dispatch({type: 'users/fullfilled', payload: data.users});
        dispatch({type: 'transportistas/fullfilled', payload: data.transportistas});
        dispatch({type: 'datos_transportistas/fullfilled', payload: data.datosTransportistas});
    } else {
        dispatch(fetchReadOnlyData());
    }
  }, []);

  return (
    <div className="dashboard-container">
      <Navbar onLogout={onLogout} userRol={userRol} />
      <Config />

      <Routes>
        <Route path='*' exact={true} element={<Navigate to={getDefaultRoute()} />}></Route>
        <Route path="/" exact element={<Navigate to={getDefaultRoute()} />}></Route>

        <Route path="/ajustes" element={<Ajustes />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/recogida" element={<Recogida />} />
        <Route path="/vin" element={<Vin />} />
        <Route path="/carrier" element={<Carrier />} />
        <Route path="/mi-cuenta" element={<MiCuenta />} />
        <Route path="/usuarios" element={<Usuarios />} />
        <Route path="/sidegal" element={<Sidegal />} />
      </Routes>

      { loading && <LoadingBackdrop /> }
      { reload && <NewVersionModal /> }

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default Main;
