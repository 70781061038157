import { useEffect, useState } from 'react';
import './vin.scss';
import { statesData } from './data';
import { toast } from 'react-toastify';
import { API_VIN } from '../shared/services/vin';
import Filter from './Filter';
import VinTable from './VinTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchVin } from '../../../../redux/features/vin';
import InfoModal from '../shared/components/modals/Info';
import VinForm from './VinForm';

const Vin = () => {
  const dispatch = useDispatch();
  const vin = useSelector(state => {
    return state.vin.entity
  }); 
  const [vinData, setVinData] = useState(null);
  const [searchBy, setSearchBy] = useState(null);
  const [infoByBastidor, setInfoByBastidor] = useState(null);
  const [filterBy, setFilterBy] = useState({
    name: '',
    value: '',
  });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [currentPart, setCurrentPart] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setVinData(vin?.data);
    if(vin?.data?.length > 0) {
      const vehiculo = vin?.data[0];

      setInfoByBastidor({
        marca: vehiculo.marca,
        modelo: vehiculo.modelo,
        matricula: vehiculo.matricula,
        kms: vehiculo.kms,
      });
    }
  }, [vin])

  const handleViewMoreInfo = (partData) => {
    setCurrentPart(partData);
    setIsDialogOpen(true);
  };

  const handleEdit = (partData) => {
    setIsEdit(true);
    setCurrentPart(partData);
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    setIsEdit(false);
    setCurrentPart(false);
  };

  const submitSearch = (searchBy, value) => {
    setInfoByBastidor({
      marca: '',
      modelo: '',
      matricula: '',
      kms: '',
    });
    setSearchBy({[searchBy]: value});
    dispatch(fetchVin({[searchBy]: value}));
  };

  const handleSavePartData = async(newData) => {
    const response = await API_VIN.editPart({newData, partId: currentPart.id})

    if(response && response.success) {
      toast.success(response.message);
      dispatch(fetchVin(searchBy));
    }
  };

  const handleOrderBy = (name) => {
    let value = 'asc';
    if (name === filterBy.name) {
      setFilterBy({
        ...filterBy,
        value: filterBy.value === 'asc' ? 'desc' : 'asc',
      });
      if (filterBy.value === 'asc') value = 'desc';
    } else {
      setFilterBy({
        name,
        value: 'asc',
      });
    }
    return submitFilters('orderBy', name, value);
  };

  const handleSearchBy = (name, value) => {
    let searchValue = value;

    if(value === "") {
      setVinData(vin?.data);
      setFilterBy({
        name,
        value,
      });
      return;
    } 

    if (name === 'estado') {
      const dataValue = statesData.find((state) =>
        state['nom'].includes(value.toLowerCase())
      );
      if (dataValue) searchValue = dataValue.estpie_cod;
    }

    setFilterBy({
      name,
      value,
    });
    submitFilters('searchBy', name, searchValue);
  };

  const submitFilters = (typeOfFilter, name, value) => {
    let filteredArr;
    if (typeOfFilter === 'orderBy') {
      value === 'asc'
        ? (filteredArr = [...vinData].sort((a, b) => {
            if(name === 'id' || name === 'precio' || name === 'estado') {
              if (a[name] > b[name]) return 1;
              if (a[name] < b[name]) return -1;
              return 0;
            } else {
              return -a[name]?.localeCompare(b[name])
            }
          }))
        : (filteredArr = [...vinData].sort((a, b) => {
            if(name === 'id' || name === 'precio' || name === 'estado') {
              if (a[name] > b[name]) return -1;
              if (a[name] < b[name]) return 1;
              return 0;
            } else {
              return -b[name]?.localeCompare(a[name])
            }
          }));
    }
    if (typeOfFilter === 'searchBy') {
      filteredArr = [...vinData].filter(
        (item) =>
          item[name] &&
          item[name]
            .toString()
            .toLowerCase()
            .includes(value.toString().toLowerCase())
      );
    }
    setVinData(filteredArr);
  };

  const resetFilters = (_, name = '') => {
    setFilterBy({
      name,
      value: '',
    });
    setVinData(vin.data);
  };


  return (
    <div className="content pt-3 vin">
      <h1>Introducir VIN</h1>

      <Filter 
        infoByBastidor={infoByBastidor}
        onSearch={submitSearch}
      />

      { vinData && (
        <VinTable
          data={vinData}
          filterBy={filterBy}
          onOrderBy={handleOrderBy}
          onFilterBy={handleSearchBy}
          onReset={resetFilters}
          onEdit={handleEdit}
          onViewMoreInfo={handleViewMoreInfo}
        />
      )}

      { (isDialogOpen) &&
        <InfoModal
          title={`Información #${currentPart.id} ${currentPart.Articulo} `}
          content={
            <VinForm
              piezaInfo={currentPart}
              isEdit={isEdit}
              onSubmit={handleSavePartData}
            />
          }
          state={isDialogOpen}
          width={"md"}
          onClose={handleCloseDialog}
        />
      }
    </div>
  );
};

export default Vin;
