import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import CustomTimePicker from "../../../shared/components/CustomTimePicker";
import moment from "moment";

export default function TransportistaForm({
    transportista, 
    onSubmitTransportista
}) {
    const { register, formState: { errors }, handleSubmit, reset, setValue } = useForm({
        mode: "onChange",
        defaultValues: {
            id: 0,
            nombre: '',
            hora_entrega: ''
        }
    });

    useEffect(() => {
        if(transportista) {
            const hora = moment(moment().format('YYYY-MM-DD') + ' ' + transportista.hora_entrega)
            reset({
                ...transportista,
                hora_entrega: hora
            });
        }
        
    }, [transportista]);


    const handleChangeHoraEntrega = (value) => {
        setValue('hora_entrega', value);
    }

    const handleSubmitTransportista = async(data, e) => {
        e.preventDefault();
        const horaEntrega = moment(data.hora_entrega).format('HH:mm:ss');
        data.hora_entrega = horaEntrega;
        onSubmitTransportista(data);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form mb-4" onSubmit={handleSubmit(handleSubmitTransportista)}>
                <input 
                    type="hidden" 
                    {...register("id")} 
                    defaultValue={transportista ? transportista.id : ''} />

                <div className="w-100 d-flex flex-column">
                    <div className="form-group me-3">
                        <label htmlFor="nombre">Nombre</label>
                        <input 
                            type="text" 
                            className="form-control" 
                            {...register("nombre", { required: true })} 
                            defaultValue={transportista ? transportista.nombre : ''} />

                        <div className="form-invalid">
                            {(errors.nombre) && errors.nombre?.message}
                        </div>
                    </div>

                    <div className="form-group me-3">
                        <CustomTimePicker 
                            value={transportista ? moment(moment().format('YYYY-MM-DD') + ' ' + transportista.hora_entrega) : null}
                            name={'hora_entrega'}
                            placeholder={transportista ? transportista.hora_entrega : ''}
                            onChangeTime={handleChangeHoraEntrega}
                        />
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end">
                    <button type="submit" className="btn btn-primary align-self-end">Guardar</button>
                </div>
            </form>
        </div>
    )
}