import { useState, useEffect } from "react";
import React from "react";
import { toast } from 'react-toastify';
import Pagination from '@mui/material/Pagination';
import { faCheck, faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from "react-redux";
import ActionsMenu from "../shared/components/ActionsMenu";
import { getActions } from "./actions";
import { API_USERS } from "../shared/services/users";
import { setErrorMessage } from "../shared/helpers/functionalities";
import { fetchUsers } from "../../../../redux/users";
import InfoModal from "../shared/components/modals/Info";
import UserForm from "./UserForm";

export default function Usuarios() {
    const dispatch = useDispatch();
    const [currentPage, setPage] = useState(1);
    const [currentUser, setCurrentUser] = useState(null);
    const [openForm, setOpenForm] = useState(false);

    const roles = useSelector(state => {
        const { roles } = state;
        return roles;
    });
    const users = useSelector(state => {
        const { users } = state;
        return users;
    });

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    }

    const getPaginationCount = () => {
        var totalPages = users.length < 15 ? 1 : Math.ceil(users.length / 15);
        return parseInt(totalPages);
    }

    const handleAction = (action, user) => {
        if(action === 'edit') {
            handleOpenForm(user);
        } else if(action === 'enable' || action === 'disable') {
            handleToggleUser(user.id)
        }
    }

    const getRoleName = (rolId) => roles.find(r => r.id === rolId);

    const handleToggleUser = async (userId) => {
        dispatch({type: 'loading/set', payload: true});
        const response = await API_USERS.toggleUser(userId)
        .catch(function (error) {
            toast.error(setErrorMessage(error))
            dispatch({type: 'loading/set', payload: false});
        });

        if(response && response.success) {
            dispatch({type: 'loading/set', payload: false});
            toast.success(response.message)
            dispatch(fetchUsers());
        } 
    }

    const handleOpenForm = (user = null) => {
        setCurrentUser(user);
        setOpenForm(true);
    }
    const handleCloseForm = () => {
        setOpenForm(false);
        setCurrentUser(null);
    }
    const handleSubmitUser = async(data) => {
        let response;

        if(currentUser) {
            response = await API_USERS.updateUser(currentUser.id, data)
            .catch(function (error) {
                dispatch({type: 'loading/set', payload: false});
                toast.error(setErrorMessage(error))
            });
        } else {
            response = await API_USERS.newUser(data)
            .catch(function (error) {
                dispatch({type: 'loading/set', payload: false});
                toast.error(setErrorMessage(error))
            });
        }

        if(response && response.success) {
            toast.success(response.message);
            dispatch(fetchUsers());
            handleCloseForm();
        }
    }

    return (
      <div className="content pt-3">
       <div className="w-100 d-flex align-items-center justify-content-end">
            <button className="btn btn-add" onClick={() => handleOpenForm()}>
                <span className="add">
                    <FontAwesomeIcon icon={faPlus} size="1x" className="action" color="#215732" />
                </span>
                    Nuevo
            </button> 
       </div>

        <div className="w-100 d-flex flex-column align-items-end content-table">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>username</th>
                  <th>Nombre</th>
                  <th className="text-center">activo</th>
                  <th className="text-center">rol</th>
                  <th className="text-center"></th>
                </tr>
              </thead>

              <tbody>
                { users?.map((user) => {
                  return (
                    <tr key={user.id}>
                      <td>{user.username}</td>
                      <td>{user.nombre}</td>
                      <td className="text-center">{user.active ? <FontAwesomeIcon icon={faCheck} color="#00986C" /> : <FontAwesomeIcon icon={faTimes} color="#DC3545" />}</td>
                      <td className="text-center text-uppercase">{roles.length > 0 ? getRoleName(user.rol_id)?.nombre : ''}</td>
                      <td width={'5%'} className="text-center">
                        <ActionsMenu 
                            options={getActions(user)}
                            onAction={(action) => handleAction(action, user)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={getPaginationCount()} 
            page={currentPage} 
            onChange={handleChangePage} 
          />
        </div>

        { (openForm) &&
          <InfoModal
            title={`${currentUser ? 'Editar usuario' : 'Nuevo usuario'}`}
            content={
                <UserForm 
                    user={currentUser}
                    onSubmitUser={handleSubmitUser}
                />
            }
            state={openForm}
            width={"sm"}
            onClose={handleCloseForm}
          />
        }
      </div>
    )
}