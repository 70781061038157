import { MenuItem, Select } from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import SignaturePad from 'react-signature-canvas';
import './steps.scss';

export default function Step2({ 
  onBack,
  onSubmitData
 }) {
  const transportistas = useSelector(state => {
    const { transportistas } = state;
    return transportistas;
  });
  const datosTransportistas = useSelector(state => {
    const { datos_transportistas } = state;
    return datos_transportistas;
  });
  const stepFormScheme = yup.object({
    transportista_id: yup.number().integer("Tienes que elegir un transportista").moreThan(0).required('El transportista es obligatorio'),
    nombre: yup.string().required("El nombre es obligatorio"),
    dni: yup.string().required("El DNI es obligatorio")
  }).required();
  const { register, formState: { errors }, handleSubmit, watch, setValue, getValues } = useForm({
    resolver: yupResolver(stepFormScheme),
    mode: "onChange",
    defaultValues: {
        transportista_id: "-1",
        transportista_datos_id: "-1",
        nombre: "",
        dni: ""
    }
  });

  const signatureCanvas = useRef({});

  useEffect(() => {
  
  }, []);

  const isFormEmpty = () => {
    const areEmpty = Object.keys(getValues()).filter(k => {
      if(k === 'transportista_datos_id') return false;
      else {
        return getValues(k) === "" || getValues(k) === null || getValues(k) === undefined || getValues(k) == -1
      }
    });
    
    return areEmpty.length > 0 ? true : false
  }

  const clearSignature = () => signatureCanvas.current.clear();

  const handleSubmitData = async(data, e) => {
    e.preventDefault();

    if(isFormEmpty()) {
      toast.warning('Te faltan campos por rellenar!');
      return;
    }

    if(signatureCanvas.current === null || signatureCanvas.current === undefined || signatureCanvas.current.isEmpty()) {
      toast.warning('Tienes que firmar para poder guardar!');
      return;
    }

    data.transportista_datos_id = data.transportista_datos_id === "-1" ? '' : data.transportista_datos_id;
    const firma = signatureCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    data.firma = firma.split(',')[1];
    onSubmitData(data);
  }

  const handleSetTransportista = (e) => { 
    setValue('transportista_id', parseInt(e.target.value)); 
    setValue('transportista_datos_id', "-1");
    setValue('nombre', '');
    setValue('dni', '');
  }

  const handleSetDatosTransportista = (e) => { 
    const value = parseInt(e.target.value);
    setValue('transportista_datos_id', value);

    if(value !== -1) {
      const datos = datosTransportistas.find(d => d.id === value);

      setValue('nombre', datos.nombre);
      setValue('dni', datos.dni);
    }
  }

  return (
    <div className='w-100'>
      <form className="form mb-4" onSubmit={handleSubmit(handleSubmitData)}>
          <div className='w-100'>
            <div className='form-group'>
              <label>Selecciona un transportista</label>
              <Select
                size="small"
                name={"transportista_id"}
                {...register("transportista_id")} 
                defaultValue={"-1"}
                onChange={(e) => handleSetTransportista(e)}>
                  <MenuItem 
                      value={"-1"}>
                      Selecciona un transportista...
                  </MenuItem>
                { transportistas.map(transportista => {
                    return(
                        <MenuItem 
                            key={'transportista-' + transportista.id}
                            value={transportista.id}>
                            {transportista.nombre}
                        </MenuItem>
                    )
                })}
              </Select>

              <div className="form-invalid">
                  {(errors.transportista_id) && errors.transportista_id?.message}
              </div>
            </div>

            { (watch('transportista_id') !== "-1") &&
              <div className='w-100'>
                { datosTransportistas.filter(d => d.transportista_id === getValues('transportista_id') )?.length > 0 ?
                  <div className='form-group'>
                    <label>¿Estás en esta lista? si no, introduce tus datos manualmente en el formulario</label>
                    <Select
                      size="small"
                      name={"transportista_datos_id"}
                      {...register("transportista_datos_id")} 
                      defaultValue={-1}
                      className='form-control'
                      onChange={(e) => handleSetDatosTransportista(e)}>
                      <MenuItem 
                        value={"-1"}>
                        Selecciona si estas en la lista...
                      </MenuItem>
                      { datosTransportistas.filter(d => d.transportista_id === getValues('transportista_id') )
                      .map(transportista => {
                          return(
                              <MenuItem 
                                  key={'transportista-' + transportista.id}
                                  value={transportista.id}>
                                  {transportista.nombre}
                              </MenuItem>
                          )
                      })}
                    </Select>
                  </div>
                  : null
                }

                { ((watch('transportista_id') !== "-1")) ?
                  <div className='w-100'>
                      <h5>Información de nueva recogida</h5>

                      <div className='form-group'>
                        <div className="form-group me-3">
                          <label htmlFor="nombre">Nombre</label>
                          <input 
                              type="text" 
                              className="form-control" 
                              {...register("nombre", {required: true})}
                              defaultValue={""} />

                          <div className="form-invalid">
                              {(errors.nombre) && errors.nombre?.message}
                          </div>
                        </div>

                        <div className="form-group me-3">
                          <label htmlFor="dni">DNI</label>
                          <input 
                              type="text" 
                              className="form-control" 
                              {...register("dni", {required: true})}
                              defaultValue={""} />

                          <div className="form-invalid">
                              {(errors.dni) && errors.dni?.message}
                          </div>
                        </div>
                      </div>
                  </div>
                  : null
                }

                <div className='w-100'>
                  <div className='form-group'>
                    <label>Firma</label>
                    

                    <SignaturePad
                      ref={signatureCanvas}
                      canvasProps={{
                        className: "signatureCanvas"
                      }}
                    />
                  </div>
                </div>
              </div>
            }
          </div>
          

          <div className="w-100 d-flex align-items-center justify-content-between">
            <button 
              type="button" 
              className="btn btn-warning"
              hidden={watch('transportista_id') === "-1"}
              onClick={() => clearSignature()}>
              Limpiar firma
            </button>

            <div className='ms-auto'>
              <button 
                type='button' 
                className={'btn btn-default me-3'}
                onClick={onBack}
              >
                Atrás
              </button>
              <button 
                type="submit" 
                className="btn btn-primary align-self-end"
              >Finalizar</button>
            </div>
          </div>
      </form>
    </div>
  );
}
