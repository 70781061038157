import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Es, Gb } from "react-flags-select";
import { useSelector } from "react-redux";

export default function TransportistaSelector({
    onSelectTransportista
}) {
    const transportistas = useSelector(state => {
        const { transportistas } = state;
        return transportistas;
    });

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            transportista_id: ""
        }
    });

    const handleChangeTransportista = (e) => {
        const value = e.target.value;
        setValue('transportista_id', value);
    }

    const handleSubmitLanguage = async(data, e) => {
        e.preventDefault();
        
        if(data.transportista_id === "") {
            toast.warning('Tienes que seleccionar un idioma!');
            return;
        }

        onSelectTransportista(data.transportista_id);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitLanguage)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group">
                        <label htmlFor="language">Transportista</label>
                        <Select
                            size="small"
                            {...register("transportista_id", { required: true })}
                            defaultValue={""}
                            onChange={handleChangeTransportista}
                        >
                            <MenuItem value={""}>
                                Selecciona un transportista...
                            </MenuItem>
                            { transportistas?.map(transportista => {
                                return (
                                    <MenuItem key={'transportista-' + transportista.id} value={transportista.id}>
                                        {transportista.nombre}
                                    </MenuItem>
                                )
                            }) }
                        </Select>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Generar</button>
                </div>
            </form>
        </div>
    )
}