import { useState, useEffect } from "react";
import React from "react";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Transportistas from "./Transportistas";

export default function Ajustes() {
    const [tab, setTab] = useState('transportistas');

    useEffect(() => {
        
    }, []);

    const handleChangeTab = (e, tab) => setTab(tab);
  
    return (
        <div className="content ajustes">
            <TabContext value={tab}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChangeTab} aria-label="Ajustes">
                    <Tab label="Transportistas" value="transportistas" />
                </TabList>
                </Box>
                <TabPanel value="transportistas">
                    <Transportistas />
                </TabPanel>
            </TabContext>
        </div>
    )
}