import API from '../../../../../services/api';

export const API_READ_ONLY = {
    getReadOnlyData: async () => {
        return await API.get('/read_only')
        .then(res => res.data);
    },
    getDatosTransportistas: async () => {
        return await API.get('/read_only/datos_transportista')
        .then(res => res.data);
    },
};