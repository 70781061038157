import API from '../../../../../services/api';

export const API_VIN = {
    searchPartsByVin: async (param) => {
        return await API.post('/vin/parts', param)
        .then(res => res.data);
    },
    getImagesByPartId: async (partId) => {
        return await API.post('/vin/images', {partId})
        .then(res => res.data)
    },
    getImagesByPartIdDesm: async (partId) => {
        return await API.post('/vin/imagesdesmontadas', {partId})
        .then(res => res.data)
    },
    editPart: async (data) => {
        return await API.post('/vin/editpart', data)
        .then(res => res.data)
    },
    getProductDetail: async (data) => {
        return await API.post('/vin/productdetail', data)
        .then(res => res.data)
    },
    getImagesByReferencia: async (partId) => {
        return await API.post('/vin/imagesdesmontadas/all', {partId})
        .then(res => res.data)
    }
};
