// LOAD ALL THE READ ONLY DATA
import { toast } from "react-toastify";
import { API_USERS } from "../views/Main/components/shared/services/users";
import { makeSetReducer } from "./utils";

export const usersFullfilledReducer = makeSetReducer(['users/fullfilled']);

export const fetchUsers = () => async dispatch => {
    dispatch({type: 'loading/set', payload: true});

    try {
        const response = await API_USERS.getUsers();
        const data = await response.users;
        const local = JSON.parse(localStorage.getItem('read_only'));
        localStorage.setItem('read_only', JSON.stringify({...local, users: data}));
        dispatch({type: 'users/fullfilled', payload: data});
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        toast.error(e.message)
        dispatch({type: 'loading/set', payload: false});
    }
}