import { faExclamationTriangle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { formatUrlImage } from '../../../helpers/functionalities';
import { API_VIN } from '../../../services/vin';

export default function PiezaModal({ pieza, state, width, onClose }) {
  const cdnServices = useSelector(state => {
    const { cdn_services } = state;
    return cdn_services;
  });

  const [cdn, setCdn] = useState('');
  const [images, setImages] = useState([]);

  useEffect(() => {
    fetchImages();
  }, [pieza]);

  const fetchImages = async () => {
    try {
      if (
        pieza &&
        Object.keys(pieza).length > 0 &&
        pieza.imagenes &&
        pieza.imagenes.length > 0
      ) {
        const firstImage = pieza.imagenes[0];
        setCdn(firstImage.cdn_service_id);
        if (firstImage.cdn_service_id === 4) {
          const res = await API_VIN.getImagesByReferencia(
            pieza.id_referencia_plataforma
          );
          if (res.success) {
            setImages(res.images);
          }
        } else {
          const imagesUrl = pieza.imagenes.map((image) =>
            formatUrlImage(cdnServices, image)
          );
          setImages(imagesUrl);
        }
      } else {
        setCdn(0);
        setImages([]);
      }
    } catch (error) {
      console.error(error);
      setCdn(0);
      setImages([]);
      return;
    }
  };

  const createBlobUrl = (base64Image) => {
    const base64Data = base64Image.split(',')[1]; // Remove the prefix
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    return URL.createObjectURL(blob);
  };

  return (
    <Dialog
      open={state}
      onClose={onClose}
      fullWidth={true}
      maxWidth={width || 'sm'}
      aria-labelledby="info-title"
      aria-describedby="info-description"
    >
      <DialogTitle id="info-title">
        <div className="w-100 d-flex align-items-center justify-content-between">
          <p className="text-uppercase fw-bold">{pieza.nombre}</p>
          <span title="cerrar" className="close" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} size="1x" color="#215732" />
          </span>
        </div>
        <div>
          <p>
            <u>REF</u>: {pieza.id_referencia_plataforma}
          </p>
        </div>
      </DialogTitle>
      <DialogContent>
        {images.length > 0 ? (
          <div>
            {images.map((pieza_img, index) => (
              <a
                href={cdn === 4 ? createBlobUrl(pieza_img) : pieza_img}
                key={index}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mt-4"
                  src={pieza_img}
                  alt={`Pieza ${index}`}
                  style={{ width: '100%', height: 'auto' }}
                />
              </a>
            ))}
          </div>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <FontAwesomeIcon icon={faExclamationTriangle} color='#ffc107' />
            <p className='mx-3'>No hay imágenes disponibles</p>
            <FontAwesomeIcon icon={faExclamationTriangle} color='#ffc107'  />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
