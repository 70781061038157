import React, { useState, useEffect, useRef } from 'react';
import {
  Stepper,
  Step,
  StepLabel
} from '@mui/material';
import Step1 from './steps/Step1';
import Step2 from './steps/Step2';
import { API_PEDIDOS } from '../shared/services/pedidos';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchDatosTransportistas } from '../../../../redux/readOnly';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { setErrorMessage } from '../shared/helpers/functionalities';

const steps = ['Lector de pedidos', 'Datos y firma'];

export default function Carrier() {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [pedidos, setPedidos] = useState([]);
  const [redirectMessage, setRedirectMessage] = useState(false);

  useEffect(() => {
    if (activeStep === steps.length) {
      setRedirectMessage(true);

      const timer = setTimeout(() => {
        handleReset();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setPedidos([]);
    setRedirectMessage(false);
    dispatch(fetchDatosTransportistas());
  };

  const handleSetPedidos = (data) => {
    setPedidos(data);
  };

  const handleSubmitRecogida = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    data.pedidos = pedidos.map(p => p.pedido.id);
    const response = await API_PEDIDOS.saveRecogida(data)
    .catch(function (error) {
      toast.error(setErrorMessage(error))
      dispatch({type: 'loading/set', payload: false});
    });
    
    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      dispatch(fetchDatosTransportistas());
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step1 pedidos={pedidos} setPedidos={handleSetPedidos} />;
      case 1:
        return (
          <Step2
            onBack={handleBack}
            onSubmitData={handleSubmitRecogida}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className='content w-100'>
      <div className='d-flex align-items-center justify-content-center'>
        <div className='col-12 col-md-8 col-lg-6'>
          <div className='w-100'>
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                      color: "#29a745"
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel": {
                      color: "#212529"
                  },
                  "& .MuiStepLabel-root .Mui-active": {
                      color: "#215732"
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel": {
                      color: "#212529"
                  },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                      fill: "#FFF"
                  }
              }}
            >
              {steps.map((label, index) => (
                <Step key={index}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>

          <div className='w-100'>
              {activeStep === steps.length ? (
                <div className='d-flex flex-column align-items-center justify-content-center mt-5'>
                  <p>
                    Todos los pasos han sido completados. 
                    <FontAwesomeIcon icon={faCheckCircle} className='ms-2' size="1x" color='#215732' />
                  </p>

                  {redirectMessage && (
                    <p className='text-center'>
                      Redirigiendo en 2 segundos...
                    </p>
                  )}
                </div>
              ) : (
                <div className='w-100'>
                  <div className='w-100 pt-5 pb-3'>
                    {getStepContent(activeStep)}
                  </div>

                  <div className='w-100 d-flex align-items-center justify-content-end'>
                    <button 
                      type='button' 
                      className={'btn btn-default me-3'}
                      disabled={activeStep === 0}
                      hidden={activeStep === 1}
                      onClick={handleBack}
                    >
                      Atrás
                    </button>
                    
                    <button 
                      type='button' 
                      className='btn btn-primary' 
                      disabled={
                        (activeStep === 0 && pedidos.length === 0)
                      }
                      hidden={activeStep === 1}
                      onClick={handleNext}
                    >
                     { activeStep === steps.length - 1
                        ? 'Finalizar'
                        : 'Siguiente'
                      }
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}
