import { useState } from "react";
import React from "react";
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Box } from "@mui/material";

export default function CustomTimePicker({
    name,
    label,
    placeholder,
    value,
    onChangeTime
}) {
    const [timeValue, setTimeValue] = useState(value);

    return (
        <div className="form-group  mb-0">
            {label ? <label>{label}</label> : null}
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <TimePicker
                    name={name ? name : ''}
                    label=""
                    ampm={false}
                    value={timeValue}
                    onChange={(newValue) => {
                        setTimeValue(newValue);
                        onChangeTime(newValue)
                    }}
                    placeholder={placeholder}
                    views={['hours', 'minutes', 'seconds']}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <Box className="date-picker" sx={{ position: 'relative' }}>
                            <input className="form-control" ref={inputRef} {...inputProps} />
                            {InputProps?.endAdornment}
                        </Box>
                    )}
                />
            </LocalizationProvider>
        </div>
    );
}