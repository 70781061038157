export const getActions = (item, user) => {
    const actions = [
        {
            label: 'Editar',
            icon: 'edit',
            action: 'edit'
        }
    ]

    if(item.active) {
        actions.push({
            label: 'Desactivar',
            icon: 'user-slash',
            action: 'disable'
        })
    } else {
        actions.push({
            label: 'Activar',
            icon: 'user',
            action: 'enable'
        })
    }

    return actions;
}