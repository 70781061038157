import logo from '../../../../images/logo.png';
import './albaran.scss';

const AlbaranES = (data) => {

    return (
        <div id='pdf'>
            <div className="box header">
                <h1>ALBARÁN DE ENTREGA</h1>
                <img src={logo} alt="Recomotor" />

                <div>
                    <h5>Recogido en:</h5>
                    <p>JG Recomotor SL</p>
                    <p>Almacén Recomotor en C/ Marinada, 12</p>
                    <p>25600, Balaguer, Lleida, España</p>
                    <p className="mb-0">Coordenadas (DD): <a href="https://www.google.es/maps/place/41%C2%B046'24.3%22N+0%C2%B048'56.3%22E/@41.7734087,0.813068,846m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d41.7734047!4d0.8156429?entry=ttu&g_ep=EgoyMDI0MTAwNS4yIKXMDSoASAFQAw%3D%3D">41.77340467782775, 0.8156429321266879</a></p>
                </div>
            </div>

            <div className="w-100 box pedido-info">
                <table>
                    <tbody>
                        <tr>
                            <td width="50%">
                                <div className="pedido-info-div green">
                                    <p>
                                        Número de pedido: &nbsp;
                                        <span className="fw-bold">{data.pedido.numero_presupuesto}</span>
                                    </p>
                                    <p>Fecha del pedido: {data.venta.fecha}</p>
                                    <p>ID pieza: #{data.idRef}</p>
                                    <p className="mb-0">ID de cliente: #{data.venta.cliente.id}</p>
                                </div>
                            </td>

                            <td width="50%">
                                <div className="pedido-info-div info-cliente">
                                    <h5>{data.venta.direccion_envio.nombre}</h5>
                                    <p>{data.venta.direccion_envio.direccion}</p>
                                    <p>{data.venta.direccion_envio.codigo_postal} {data.venta.direccion_envio.poblacion}, {data.venta.direccion_envio.area}, {data.venta.direccion_envio.pais.nombre}</p>
                                    <p>Teléfono: {data.venta.direccion_envio.telefono}</p>
                                    <p className="mb-0">Email: {data.venta.cliente.email ? data.venta.cliente.email : '-'}</p>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            

            <div className="w-100 box piezas">
                <table>
                    <thead>
                        <tr>
                            <th>Ref. del producto</th>
                            <th className='text-center'>Cantidad</th>
                            <th className='text-center'>Precio</th>
                        </tr>
                    </thead>
                    <tbody>
                        { data.venta.piezas.map(pieza => {
                            return (
                                <tr key={'pieza-' + pieza.id}>
                                    <td width="60%">{pieza.nombre}</td>
                                    <td className='text-center'>{pieza.ventaRelationship.unidades}</td>
                                    <td className='text-center'>{parseFloat(pieza.precio_venta).toFixed(2)}€</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            <div className="w-100 box imagenes">
                <h5 className="fw-bold">Imágenes:</h5>

                <div className="d-flex align-items-center">
                    { data.pedido.pieza.imagenes.map((img, i) => {
                        return (
                            <img 
                                key={'img-' + i}
                                src={`data:image/png;base64,${img.base64}`}
                                alt="Imagen pieza" 
                            />
                        )
                    })}
                </div>
            </div>

            <div className="w-100 mb box">
                <h5 className="fw-bold">Datos de la recogida:</h5>
                <p>Fecha y hora de la entrega: {data.pedido.fecha_recogida}</p>
                <p>Empresa de transporte: {data.pedido.transportista.transportista.nombre}</p>
                <p>Conductor: {data.pedido.transportista.nombre}</p>
                <p>DNI: {data.pedido.transportista.dni}</p>
                
                <div className='firma'>
                    <p>
                        <span className="fw-bold">Firma</span>
                    </p>
                    <img 
                        src={`data:image/png;base64,${data.pedido.firma}`}
                        alt="Firma del transportista"  
                    />
                </div>
            </div>

            <footer className="w-100 box">
                <div className="w-100 footer">
                    <h5>Datos de contacto:</h5>
                    <p>Teléfono: +34 621 24 52 58</p>
                    <p>Email: ventaonline.recomotor@gmail.com</p>
                    <p><a href="https://recomotor.com">https://recomotor.com</a></p>
                </div>
            </footer>
        </div>
    );
};

export default AlbaranES;