import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { API_PIEZAS_SIDEGAL } from "../../views/Main/components/shared/services/sidegal";

const initiaSidegal = [];

export const sidegalReducer = (state = initiaSidegal, action) => {
    switch(action.type){
        case "piezas_sidegal/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncSidegal = makeAsyncTypes('piezas_sidegal');
const [setPending, setFullFilled, setError ] = asyncMac(asyncSidegal);

export const sidegalFetchingReducer = makeFetchingReducer([
    'piezas_sidegal/pending', 
    'piezas_sidegal/fullfilled', 
    'piezas_sidegal/rejected'
]);

export const fetchPiezasSidegal = (page, filters = null) => async dispatch => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(setPending());

    try {
        const response = await API_PIEZAS_SIDEGAL.getPiezasSidegal(page, filters);
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const sidegalStatusReducer = sidegalFetchingReducer