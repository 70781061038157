import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { Es, Gb } from "react-flags-select";

export default function LanguageSelector({
    onSelectLanguage
}) {
    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            language: ""
        }
    });

    const handleChangeLanguage = (e) => {
        const value = e.target.value;
        setValue('language', value);
    }

    const handleSubmitLanguage = async(data, e) => {
        e.preventDefault();
        
        if(data.language === "") {
            toast.warning('Tienes que seleccionar un idioma!');
            return;
        }

        onSelectLanguage(data.language);
    }
    
    return (
        <div className="w-100 d-flex flex-column">
            <form className="form align-items-center mb-4" onSubmit={handleSubmit(handleSubmitLanguage)}>
                <div className="w-100 d-flex flex-column flex-grow-1">
                    <div className="form-group">
                        <label htmlFor="language">Idioma</label>
                        <Select
                            size="small"
                            {...register("language", { required: true })}
                            defaultValue={""}
                            onChange={handleChangeLanguage}
                        >
                            <MenuItem value={""}>
                                Selecciona un idioma...
                            </MenuItem>
                            <MenuItem value={'es'}>
                                <Es className="me-2" /> Español
                            </MenuItem>
                            <MenuItem value={'en'}>
                                <Gb className="me-2" /> Ingles
                            </MenuItem>
                        </Select>
                    </div>
                </div>

                <div className="d-flex align-items-center align-self-end ms-3 mt-4">
                    <button type="submit" className="btn btn-primary align-self-end">Generar</button>
                </div>
            </form>
        </div>
    )
}