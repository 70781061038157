import React from "react";
import { getStatusPedido } from "../../../shared/helpers/functionalities";
import moment from "moment-timezone";

export default function LogsTable({
    logs
}) {
    
    return (
        <div className="w-100 table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th>Estado</th>
                        <th className="text-center"></th>
                        <th className="text-center">Responsable</th>
                    </tr>
                </thead>
                <tbody>
                    { logs.map(log => {
                        return (
                            <tr>
                                <td>
                                    <span className={'fw-bold ' + (getStatusPedido(log.estado_id))}>{log.estado.nombre}</span>
                                </td>
                                <td>{moment(log.createdAt).tz('Europe/Madrid').format('DD-MM-YYYY HH:mm:ss')}</td>
                                <td className="text-center">
                                    {log.responsable ? log.responsable.username : '-'}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}