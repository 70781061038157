import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { API_PEDIDOS } from "../../views/Main/components/shared/services/pedidos";

const initialDashboard = { };

export const superDashboardReducer = (state = initialDashboard, action) => {
    switch(action.type){
        case "dashboard/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncDashboard = makeAsyncTypes('dashboard');
const [setPending, setFullFilled, setError ] = asyncMac(asyncDashboard);

export const dashboardFetchingReducer = makeFetchingReducer([
    'dashboard/pending', 
    'dashboard/fullfilled', 
    'dashboard/rejected'
]);

export const fetchDashboard = (page = 1, status, userId = null, filters = null) => async dispatch => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(setPending());

    try {
        const response = await API_PEDIDOS.getPedidos(page, status, userId, filters);
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const dashboardReducer = superDashboardReducer;
export const dashboardStatusReducer = dashboardFetchingReducer