import { toast } from "react-toastify";
import { asyncMac, makeAsyncTypes, makeFetchingReducer } from "../utils";
import { API_VIN } from "../../views/Main/components/shared/services/vin";

const initialVin = [];

export const vinReducer = (state = initialVin, action) => {
    switch(action.type){
        case "vin/fullfilled": {
            return action.payload;
        }
        default:
            return state
    }
}

const asyncVin = makeAsyncTypes('vin');
const [setPending, setFullFilled, setError ] = asyncMac(asyncVin);

export const vinFetchingReducer = makeFetchingReducer([
    'vin/pending', 
    'vin/fullfilled', 
    'vin/rejected'
]);

export const fetchVin = (params) => async dispatch => {
    dispatch({type: 'loading/set', payload: true});
    dispatch(setPending());

    try {
        const response = await API_VIN.searchPartsByVin(params);
        const data = await response.data;
        dispatch(setFullFilled(data));
        dispatch({type: 'loading/set', payload: false});
    } catch (e) {
        dispatch({type: 'loading/set', payload: false});
        dispatch(setError(e.message))
        toast.error(e.message)
    }
}

export const vinStatusReducer = vinFetchingReducer